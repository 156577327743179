import vertexShader from "./data.vert"
import fragmentShader from "./data.frag"

export default {
    uniforms: {
        tDiffuse: { value: null },
        textureData: { value: null },
        extrudeMin: { type: "f", value: 0 },
        extrudeMax: { type: "f", value: 10 },
        time: { type: "f", value: 0 },
    },
    vertexShader,
    fragmentShader,
}
