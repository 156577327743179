import {
    Scene,
    PerspectiveCamera,
    OrthographicCamera,
    VertexColors,
    Color,
    Mesh,
    UniformsUtils,
    ImageUtils,
    SphereGeometry,
    ShaderMaterial,
    Vector3,
    Vector2,
    FaceColors,
    Geometry,
    Object3D,
    RepeatWrapping,
    BackSide,
    DoubleSide,
    // OBJLoader,
} from "three"

import gridModel from "./models/gridLand3.obj"
import pointModel from "./models/cube.obj"

import earthShader from "./shaders/earth"
import worldImage from "./images/world2.jpg"

import worldMask from "./images/worldMask.jpg"
import worldDataSample from "./images/worldData.jpg"

import atmosphereShader from "./shaders/atmosphere"
import dataShader from "./shaders/globeDataShader"

import modelLoader from "./modelLoader"

class Cover {
    fov = 45
    // gridDensity = 6
    pointType = "hex"
    pointScale = 0.5
    pointExtrudeRange = [0.01, 400]

    distance = 80000
    distanceTarget = 4000
    curZoomSpeed = 0
    rotation = { x: 0, y: 0 }
    canRender = false
    rotationAxis = new Vector3(0.2, 1, 0.2)

    rotationObj = new Object3D()
    leanObj = new Object3D()
    hasInit = false

    size = 2.2
    posX = -600

    constructor({ width, height }) {
        this.scene = new Scene()

        this.atmosphereScene = new Scene()

        this.camera = new PerspectiveCamera(this.fov, width / height, 1, 4000)

        const ratio = height / width
        const camWidth = 1920
        const camHeight = camWidth * ratio

        this.camera = new OrthographicCamera(
            camWidth / -2,
            camWidth / 2,
            camHeight / 2,
            camHeight / -2,
            1,
            2000
        )

        this.camera.position.z = 1000
        this.camera.position.x = -200

        this.leanObj.add(this.rotationObj)
        this.scene.add(this.leanObj)

        this.leanObj.rotation.z = -0.2
        this.leanObj.rotation.x = 0.3
        // this.leanObj.rotation.x = 0.2

        this.loadModel(pointModel)
        this.loadModel(gridModel)

        // this.init()
    }

    init = async () => {
        if (this.hasInit) return
        this.hasInit = true

        const [p, g] = await Promise.all([
            this.loadModel(pointModel),
            this.loadModel(gridModel),
        ])

        this.pointGeo = new Geometry().fromBufferGeometry(
            p.children[0].geometry
        )

        const grid = g.children[0]

        grid.rotation.y = Math.PI
        grid.updateMatrix()
        grid.geometry.applyMatrix(grid.matrix)

        this.gridGeo = new Geometry().fromBufferGeometry(grid.geometry)

        this.genGlobe()
        this.genAtmosphere()

        this.point = new Mesh(this.pointGeo)
        this.createPoints()

        this.resize(window.innerWidth, window.innerHeight)
        this.canRender = true
    }

    loadModel = model => {
        return new Promise(resolve => {
            modelLoader.load(model, resolve)
        })
    }

    createPoints = () => {
        const { gridGeo } = this
        const { vertexShader, fragmentShader, uniforms: u } = dataShader

        const uniforms = UniformsUtils.clone(u)

        uniforms.tDiffuse.value = ImageUtils.loadTexture(worldMask)
        uniforms.textureData.value = ImageUtils.loadTexture(worldDataSample)

        uniforms.textureData.value.wrapS = uniforms.textureData.value.wrapT = RepeatWrapping

        uniforms["extrudeMin"].value = this.pointExtrudeRange[0]
        uniforms["extrudeMax"].value = this.pointExtrudeRange[1]

        this.material = new ShaderMaterial({
            uniforms,
            vertexShader,
            fragmentShader,
            color: 0xffffff,
            // vertexColors: FaceColors,
            // side: DoubleSide,
        })

        this.points = new Mesh(new Geometry(), this.material)

        // this.points.frustumCulled = false

        for (let i = 0; i < gridGeo.vertices.length; i++) {
            const x = gridGeo.vertices[i].x
            const y = gridGeo.vertices[i].y
            const z = gridGeo.vertices[i].z
            const theta = Math.acos(y / 200) / Math.PI
            const phi = (Math.atan2(z, -x) + Math.PI) / (Math.PI * 2)

            this.addPoint(x, y, z, phi, theta)
        }

        this.points.doubleSided = true
        this.rotationObj.add(this.points)
    }

    addPoint = (x, y, z, u, v) => {
        const { point } = this
        point.position.set(x, y, z)
        point.scale.set(this.pointScale, this.pointScale, 0.2)
        point.lookAt(this.sphere.position)
        point.updateMatrix()

        for (let i = 0; i < point.geometry.faceVertexUvs[0].length; i++) {
            for (
                let j = 0;
                j < point.geometry.faceVertexUvs[0][i].length;
                j++
            ) {
                point.geometry.faceVertexUvs[0][i][j] = new Vector2(u, v)
            }
        }

        this.points.geometry.merge(point.geometry, point.matrix)
    }

    genAtmosphere = () => {
        const geometry = new SphereGeometry(200, 40, 40)

        const { vertexShader, fragmentShader, uniforms: u } = atmosphereShader
        const uniforms = UniformsUtils.clone(u)
        const material = new ShaderMaterial({
            uniforms,
            vertexShader,
            fragmentShader,
            side: BackSide,
            transparent: true,
        })

        this.atmosphere = new Mesh(geometry, material)

        this.atmosphere.scale.x = this.atmosphere.scale.y = this.atmosphere.scale.z =
            this.size * 1.1
        this.atmosphere.matrixAutoUpdate = false
        this.atmosphere.updateMatrix()
        this.atmosphereScene.add(this.atmosphere)
    }

    genGlobe = () => {
        const geometry = new SphereGeometry(200, 100, 100)
        const { vertexShader, fragmentShader, uniforms: u } = earthShader
        const uniforms = UniformsUtils.clone(u)

        uniforms.tDiffuse.value = ImageUtils.loadTexture(worldMask)

        const material = new ShaderMaterial({
            uniforms,
            vertexShader,
            fragmentShader,
        })

        this.sphere = new Mesh(geometry, material)
        this.rotationObj.add(this.sphere)
    }

    colorFn = x => {
        var c = new Color()
        c.setHSV(0.6 - x * 0.5, 1.0, 1.0)
        return c
    }

    resize = (width, height) => {
        this.width = width
        this.height = height
        // this.camera.aspect = this.width / this.height

        const ratio = height / width
        const camWidth = 1920
        const camHeight = camWidth * ratio

        this.camera.left = camWidth / -2
        this.camera.right = camWidth / 2
        this.camera.top = camHeight / 2
        this.camera.bottom = camHeight / -2

        this.camera.updateProjectionMatrix()

        if (width >= 1024) {
            this.size = 2.2
            this.posX = -600
        } else {
            this.size = 4
            this.posX = 0
        }

        if (!this.atmosphere) return

        this.rotationObj.scale.z = this.rotationObj.scale.x = this.rotationObj.scale.y = this.size
        this.atmosphere.scale.x = this.atmosphere.scale.y = this.atmosphere.scale.z =
            this.size * 1.1
        this.atmosphere.updateMatrix()
    }

    zoom = delta => {
        this.distanceTarget -= delta
        this.distanceTarget =
            this.distanceTarget > 1000 ? 1000 : this.distanceTarget
        this.distanceTarget =
            this.distanceTarget < 350 ? 350 : this.distanceTarget
    }

    update = progress => {
        if (!this.canRender) return

        const t = window.performance.now() * 0.0001

        this.material.uniforms.time.value = t

        this.distance += (this.distanceTarget - this.distance) * 0.3
        this.rotationObj.rotation.y = t + Math.PI

        this.camera.position.y = 150 - progress * 150
        this.camera.position.z = 1200
        this.camera.position.x = this.posX

        this.atmosphere.material.uniforms.atmosPosY.value = -progress
    }

    render = (renderer, target = null) => {
        if (!this.canRender) return
        // console.log("hasREndererd")
        renderer.setRenderTarget(target)
        renderer.clearDepth()
        renderer.render(this.atmosphereScene, this.camera)
        renderer.clearDepth()
        renderer.render(this.scene, this.camera)
        // renderer.setRenderTarget(null)
    }
}

export default Cover
