module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"open-avn","short_name":"open-avn","start_url":"/","display":"minimal-ui","icon":"src/images/openavn-icon.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"cf5b2a634b9cca286022a9af5f29a29c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/buildhome/repo/src/components/Layout.js"},
    },{
      plugin: require('../plugins/gatsby-plugin-graphql-runtime/gatsby-browser.js'),
      options: {"plugins":[],"url":"http://34.233.18.129/api","typeName":"CRAFT","fieldName":"craft"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
